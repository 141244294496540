<template>
<div class="container">
  <div class="banner">
    <div class="text">数/据/支/撑</div>
  </div>
  <div class="u-flex u-row-center u-m-t-40 u-m-b-20">
    <div class="ball-1"></div>
    <div class="auto-title u-font-30">数据服务</div>
    <div class="ball-2">
      <div class="ball-3"></div>
    </div>
  </div>
  <div class="service-box">
    <div class="item">
      <div>数据支撑是指企业在决策和运营过程中，依靠数据分析和挖掘，获取有价值的信息和洞察，从而支持企业的战略规划和业务发展。数据支撑包括数据采集、数据存储、数据处理、数据分析等多个环节。通过数据支撑，企业可以更加深入地了解市场和客户需求，优化产品和服务，提高效率和质量，降低成本和风险，从而增强企业的竞争力。</div>
    </div>
    <div class="item">
      <img class="wi100 hi100" style="object-fit: cover" src="../../../../src/static/img/data-support/u1630.jpg" />
    </div>
    <div class="item">
      <img class="wi100 hi100" style="object-fit: cover" src="../../../../src/static/img/data-support/u1629.jpg" />
    </div>
    <div class="item">
      <img class="wi100 hi100" style="object-fit: cover" src="../../../../src/static/img/data-support/u1631.jpg" />
    </div>
  </div>
  <div class="bg-box"></div>
  <div class="u-flex u-row-center u-m-t-50 u-m-b-30">
    <div class="ball-1"></div>
    <div class="auto-title u-font-30 c-white">技术方案</div>
    <div class="ball-2">
      <div class="ball-3"></div>
    </div>
  </div>
  <div class="scheme-box u-flex u-col-top u-m-b-80">
    <img class="wi50" style="object-fit: cover" src="../../../../src/static/img/data-support/u1637.png" />
    <div class="u-flex-1 u-m-l-20 c-white u-font-18 lh3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;首先确定数据支撑目标和采集方式，然后建立数据存储系统并进行 数据处理和分析，最后数据输出可视化和报告。数据支撑需要依靠先进的技术和工具，如大数据、人工智能、机器学习等，同时也需要注重数据质量和安全，建立完善的数据管理体系和流程。数据支撑还需要注重人才培养和团队协作，建立高效的沟通机制和流程，以实现数据的有效利用和价值创造。</div>
  </div>
  <div class="tac mb-3 show-more">
    <el-button class="wi30" type="primary" round @click="toComponents">查看更多</el-button>
  </div>
</div>
</template>

<script>
export default {
  name: 'index',
  methods: {
    toComponents () {
      window.open(`http://localhost:8002`, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
  .banner{
    width: 100%;
    height: 460px;
    background: url("../../../static/img/data-support/u1514.png") no-repeat center center;
    background-size: cover;
    margin-top: -60px;
    overflow: hidden;
    .text{
      font-size: 70px;
      letter-spacing: 20px;
      color: #fff;
      text-align: center;
      padding-top: 200px;
    }
  }
  .show-more {
    padding: 20px;
    position: sticky;
    bottom: 0;
    background: white;
  }
  .ball-1{
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-image: linear-gradient( -45deg, #FD00E8FF, #6E8EE9FF );
    margin-bottom: 60px;
    margin-right: 10px;
  }
  .ball-2{
    position: relative;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-image: linear-gradient( -45deg, #fcd005, #7cb301);
    margin-top: 60px;
    margin-left: -6px;
  }
  .ball-3{
    position: absolute;
    right: -2px;
    bottom: -2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-image: linear-gradient( -45deg, #0c86f3, #7cb301);
    margin-top: 60px;
    margin-left: -6px;
  }
  .service-box{
    width: 1075px;
    background-color: #fff;
    padding: 20px;
    margin: 0 auto;
    box-sizing: border-box;
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(2, 1fr);
    box-shadow: 0 0 16px rgba(#000, 0.02);
    .item{
      height: 300px;
      background-image: linear-gradient( -45deg, #0085ef, #00c8fe);
      display: flex;
      align-items: center;
      justify-content: center;
      &>div{
        color: #fff;
        padding: 20px;
        box-sizing: border-box;
        line-height: 2;
        font-size: 18px;
      }
    }
  }
  .bg-box{
    position: absolute;
    left: 0;
    top: 1450px;
    width: 100%;
    height: 768px;
    z-index: -1;
    margin-top: -200px;
    background: url("../../../static/img/data-support/u1512.png") no-repeat center center;
    background-size: cover;
    filter: grayscale(30%) brightness(50%);
  }
  .scheme-box{
    width: 1075px;
    margin: 0 auto;
  }
</style>
